import React, { Component } from 'react';
import { navigate } from 'gatsby'
import { withFirebase } from '../Firebase';
import { ACCOUNT } from '../../constants/routes'

const INITIAL_STATE = {
  passwordOne: '',
  passwordTwo: '',
  error: null,
  loading: false,
};

class PasswordChangeForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { passwordOne } = this.state;
    this.setState({ loading:true });
    this.props.firebase
      .doPasswordUpdate(passwordOne)
      .then(() => {
        this.setState({ ...INITIAL_STATE , loading:false });
        navigate(ACCOUNT)
      })
      .catch(error => {
        this.setState({ error ,loading:false });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { passwordOne, passwordTwo, error, loading } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo || passwordOne === '';

    return (
      <div>
      <h2>Reset Password:</h2>   
        <form className="mb-0 mt-3 pb-2" onSubmit={this.onSubmit} style={{display: 'flex',alignItems: 'center',justifyContent: 'center',flexDirection: 'column'}}>
          <div className="input-style input-style-2 input-required ml-2 mr-2">
            <span className="color-highlight input-style-1-active">New Password</span>
            <em>(required)</em>
            <input className="form-control"
              name="passwordOne"
              value={passwordOne}
              onChange={this.onChange}
              type="password"
              placeholder="New Password"
              style={{minWidth: '150px', width: '80vw'}}
            />
          </div>
          <div className="input-style input-style-2 input-required ml-2 mr-2">
            <span className="color-highlight input-style-1-active">New Password</span>
            <em>(required)</em>
            <input className="form-control"
              name="passwordTwo"
              value={passwordTwo}
              onChange={this.onChange}
              type="password"
              placeholder="Confirm New Password"
              style={{minWidth: '150px', width: '80vw'}}
            />
          </div>
          <button 
            className="btn btn-s rounded-xl font-600 shadow-s bg-blue1-dark  ml-2 mr-2c"  
            disabled={isInvalid} type="submit">
            { loading ? 
              <div className="spinner-border-sm spinner-border color-white" role="status"></div>:
              "Reset My Password"
            }
          </button>

          {error && <p>{error.message}</p>}
        </form>
      </div>
    );
  }
}

export default withFirebase(PasswordChangeForm);
