import React, { Fragment } from 'react';
import { compose } from 'recompose';
import SignOutButton from '../components/SignOut';
import Layout from '../components/layout';
import {
  AuthUserContext,
  withAuthorization,
  withEmailVerification,
} from '../components/Session';
import PasswordForgetForm from '../components/PasswordForget';
import PasswordChangeForm from '../components/PasswordChange';
import LoginManagement from '../components/LoginManagement';

const AccountPageBase = () => (
  <Fragment>
    <AuthUserContext.Consumer>

      {authUser => (
      <div className="page-content pb-0">
        <div className="card" data-card-height="cover" style={{height: '100vh'}}>
          <div className="card-center">
            <div className="content mr-5 ml-5 mb-0">
              <div>
                <h1>Account: {authUser.email}</h1>
                <PasswordForgetForm />
                <PasswordChangeForm />
                <LoginManagement authUser={authUser} />
              </div>
              <div>
                <SignOutButton />
              </div>
            </div>
          </div>
        </div>
      </div>
      )}

    </AuthUserContext.Consumer>

  </Fragment>
);

const condition = authUser => !!authUser;

const AccountPage = compose(
  withEmailVerification,
  withAuthorization(condition),
)(AccountPageBase);

export default () => (
  <Layout>
    {/* <AccountPage /> */}
  </Layout>
);
