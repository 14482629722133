import React from 'react';

import { withFirebase } from '../Firebase';

const SignOutButton = ({ firebase }) => (
  <button
    type="button"
    onClick={firebase ? firebase.doSignOut : () => {}}
    className="btn btn-s rounded-xl font-600 bg-red1-dark color-white"
  >
    Sign Out
  </button>
);

export default withFirebase(SignOutButton);
