import React, { Component } from 'react';
import { navigate } from 'gatsby'
import { withFirebase } from '../Firebase';
import { ACCOUNT } from '../../constants/routes';
import PasswordChangeForm from '../PasswordChange';

const SIGN_IN_METHODS = [
  {
    id: 'password',
    icon: 'fa fa-key',
    provider: null,
  },
  {
    id: 'google.com',    
    icon: 'fab fa-google',
    provider: 'googleProvider',
  },
  {
    id: 'facebook.com',
    icon: 'fab fa-facebook-f',
    provider: 'facebookProvider',
  },
  {
    id: 'twitter.com',
    icon: 'fab fa-twitter',
    provider: 'twitterProvider',
  },
];

class LoginManagement extends Component {
  _initFirebase = false;

  constructor(props) {
    super(props);

    this.state = {
      activeSignInMethods: [],
      didFetchSignInMethods: false,
      error: null,
    };
  }

  firebaseInit = () => {
    if (this.props.firebase && !this._initFirebase) {
      this._initFirebase = true;

      this.fetchSignInMethods();
    }
  };

  componentDidMount() {
    this.firebaseInit();
  }

  componentDidUpdate() {
    this.firebaseInit();
  }

  fetchSignInMethods = () => {
    this.setState({didFetchSignInMethods: false,});
    this.props.firebase.auth
      .fetchSignInMethodsForEmail(this.props.authUser.email)
      .then(activeSignInMethods =>
        this.setState({ 
          activeSignInMethods,
          didFetchSignInMethods: true,
          error: null
        }),
      )
      .catch(error => this.setState({ didFetchSignInMethods: true,error }));
  };

  onSocialLoginLink = provider => {
    this.props.firebase.auth.currentUser
      .linkWithPopup(this.props.firebase[provider])
      .then(this.fetchSignInMethods)
      .catch(error => this.setState({ error }));
  };

  onDefaultLoginLink = password => {
    const credential = this.props.firebase.emailAuthProvider.credential(
      this.props.authUser.email,
      password,
    );

    this.props.firebase.auth.currentUser
      .linkAndRetrieveDataWithCredential(credential)
      .then(this.fetchSignInMethods)
      .catch(error => this.setState({ error }));
  };

  onUnlink = providerId => {
    this.props.firebase.auth.currentUser
      .unlink(providerId)
      .then(this.fetchSignInMethods)
      .catch(error => this.setState({ error }));
  };

  render() {
    const { activeSignInMethods,didFetchSignInMethods, error } = this.state;

    return (
      <div>
        <div className="card card-style">
            <div className="content mb-2">
              <h2>Sign In Methods:</h2>        
              {error && <span className="color-red1-dark">{error.message}</span>}
              <div className="list-group list-custom-small list-icon-0 mt-2">  
                {didFetchSignInMethods && SIGN_IN_METHODS.map(signInMethod => {
                  const onlyOneLeft = activeSignInMethods.length === 1;
                  const isEnabled = activeSignInMethods.includes(
                    signInMethod.id,
                  );
                  if(signInMethod.id === 'password'){
                      return <DefaultLoginToggle 
                                key={signInMethod.id}
                                onlyOneLeft={onlyOneLeft}
                                isEnabled={isEnabled}
                                signInMethod={signInMethod}
                                onLink={this.onDefaultLoginLink}
                                onUnlink={this.onUnlink}
                            />
                  }else{
                      return <SocialLoginToggle
                                key={signInMethod.id}
                                onlyOneLeft={onlyOneLeft}
                                isEnabled={isEnabled}
                                signInMethod={signInMethod}
                                onLink={this.onSocialLoginLink}
                                onUnlink={this.onUnlink}
                            />
                  }
                })}
                  {
                  !didFetchSignInMethods &&
                  <div className="text-center mt-3"><div className="spinner-border color-highlight" role="status"></div></div>
                  }
                </div>
            </div>
        </div>

        {activeSignInMethods.includes('password') &&
        <div className="card card-style">
            <div className="content mb-2">   
              <PasswordChangeForm />
            </div>
        </div>
        }

        
      </div>
    );
  }
}

const SocialLoginToggle = ({
  onlyOneLeft,
  isEnabled,
  signInMethod,
  onLink,
  onUnlink,
}) =>
  <a>
    <i className={signInMethod.icon + ` font-14 rounded-s color-facebook`} ></i>
    <span>{signInMethod.id}</span> 
    {isEnabled && <i className="ml-1 fa font-14 color-green2-dark  fa-check "></i>}
    {isEnabled &&
      <button
        type="button"
        onClick={() => onUnlink(signInMethod.id)}
        disabled={onlyOneLeft}
        className="btn btn-s rounded-xl font-600 shadow-s bg-red2-light  custom-control "
      >
        Unlink
      </button>
    }
    {!isEnabled && 
      <button className="btn btn-s rounded-xl font-600 shadow-s bg-green2-dark  custom-control "  onClick={() => onLink(signInMethod.provider)}>
        Link
      </button>
    }
  </a>
  ; 
class DefaultLoginToggle extends Component {
  constructor(props) {
    super(props);

    this.state = { passwordOne: '', passwordTwo: '' };
  }

  onSubmit = event => {
    event.preventDefault();

    this.props.onLink(this.state.passwordOne);
    this.setState({ passwordOne: '', passwordTwo: '' });
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const {
      onlyOneLeft,
      isEnabled,
      signInMethod,
      onUnlink,
    } = this.props;

    const { passwordOne, passwordTwo } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo || passwordOne === '';

    return isEnabled ? 
  (   <a>
        <i className={signInMethod.icon + ` fab font-14 rounded-s color-facebook`} ></i>
        <span>{signInMethod.id}</span> 
        {isEnabled && <i className="ml-1 fa font-14 color-green2-dark  fa-check "></i>}
        {isEnabled &&
          <button
            type="button"
            onClick={() => onUnlink(signInMethod.id)}
            disabled={onlyOneLeft}
            className="btn btn-s rounded-xl font-600 shadow-s bg-red2-light  custom-control "
          >
            Unlink
          </button>
        }
      </a>
    ) : (
      <form className="mb-0 mt-3 pb-2" onSubmit={this.onSubmit} style={{display: 'flex',alignItems: 'center',justifyContent: 'center',flexDirection: 'column', borderBottom: 'solid 1px rgba(0, 0, 0, 0.05)'}}>
        <div className="input-style input-style-2 input-required ml-2 mr-2">
          <span className="color-highlight input-style-1-active">Password</span>
          <em>(required)</em>
          <input className="form-control"
            name="passwordOne"
            value={passwordOne}
            onChange={this.onChange}
            type="password"
            placeholder="New Password"
            style={{minWidth: '150px', width: '80vw'}}
          />
        </div>
        <div className="input-style input-style-2 input-required  ml-2 mr-2">
          <span className="color-highlight input-style-1-active">Confirm New Password</span>
          <em>(required)</em>
          <input className="form-control"
            name="passwordTwo"
            value={passwordTwo}
            onChange={this.onChange}
            type="password"
            placeholder="Confirm New Password"
            style={{minWidth: '150px', width: '80vw'}}
          />
        </div>
        <button 
          className="btn btn-s rounded-xl font-600 shadow-s bg-green2-dark  ml-2 mr-2c"  
          disabled={isInvalid} 
          type="submit">
          Link {signInMethod.id}
        </button>
      </form>
    );
  }
}

export default withFirebase(LoginManagement);
